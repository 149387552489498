body {
  margin: 0;
  font-family: 'Bebas Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  transition: background-color 0.3s ease;
  overflow-x: hidden;
  
  background-image: url("./images/bg_winter.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
}

@media screen and (max-width: 1430px) {
  body {
    background-position: center;
    background-size: cover !important;
  }
}

html {
  overflow-x: hidden;
}

.swiper-button-prev {
  color: #000 !important;
}
.swiper-button-next {
  color: #000 !important;
}

table {
  th, td {
    color: #fff !important;
  }
  tr {
    cursor: pointer;
    td {
      color: #fff !important;
      
    }
    &:hover {
      td {
        color: rgba(255, 255, 255, 0.7) !important;
      }
    }
  }
}


.ant-pagination .ant-pagination-item a {
  color: #fff !important;
}
.ant-pagination-item-link {
  color: #fff !important;
}

.ant-pagination .ant-pagination-item-active a {
  color: #000 !important;
}
* {
  scroll-behavior: smooth;
}
.ant-select-dropdown {
  z-index: 999999999999;
}

.fader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  background-color: #000000;
  transition: all 0.4s ease;
  z-index: 9999999999999;
}

.ant-message {
  z-index: 999999999999;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.centered {
  display: flex;
  align-items: center;
}

.loading {
  text-align: center;
  padding: 240px 0 0;
}

.swiper {
  padding-bottom: 90px;
}

@media screen and (max-width: 768px) {
  .swiper {
    padding-bottom: 40px;
  }
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 30px !important;
}

.swiper-pagination-bullet {
  background: #fff !important;
  transition: all 0.1s ease;
}

.swiper-pagination-bullet-active {
  background: #fff !important;
  border: 2px solid #fff !important;
  padding: 5px !important;
}

.modal_text {
  font-size: 14px;
  color: #000;
  padding-bottom: 4px;
}

.modal_text {
  font-size: 16px;
  font-family: 'RobotoCondensed';
  font-weight: 400;
  letter-spacing: 0.5px;
  color: #000;
}

.modal_text p {
  margin-top: -8px;
  text-align: center;
  margin-bottom: 28px;
}

.modal_action {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
  z-index: 999999999;
  padding: 0px 16px 20px 16px;
}

.Toastify {
  position: relative;
  z-index: 9999999999999999;
  width: 100%;
}

.modal_action div {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.modal_action button {
  color: #fff;
  background: transparent !important;
  outline: none !important;
  border: none !important;
  text-transform: uppercase;
  font-family: 'Futura PT';
  font-weight: 500;
}

.ant-select-selection-item {
  color: #000 !important;
}

.modal_action a {
  color: #fff;
  font-weight: 500;
  background: transparent !important;
  outline: none !important;
  border: none !important;
  text-transform: uppercase;
  text-decoration: none;
  margin-left: 12px;
}

@media screen and (max-width: 960px) {
  .modal_actions {
    div {
      flex-direction: column !important;
      button {
        margin-top: 10px;
        margin-right: 0px !important;
        &:first-child {
          margin-top: 0px !important;
        }
      }
    }
  }
}

.modal_actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    button {
      border-radius: 100px;
      background-color: rgba(255, 255, 255, 0.15);
      color: #000;
      border: none;
      outline: none;
      font-size: 16px;
      padding: 6px 18px;
      margin-right: 12px;
      &:last-child {
        margin-right: 0px !important;
      }
      &.prim {
        background-color: #107be7 !important;
        color: #fff;
      }
      &.green {
        background-color: rgb(11, 177, 11) !important;
        color: #fff;
      }
      &.red {
        background-color: rgb(216, 7, 7) !important;
        color: #fff;
      }
      &.clear {
        background-color: transparent !important;
      }
    }
  }
}

.modal_action button:first-child {
  color: #fff;
  font-weight: 500;
  background: #FF0000 !important;
  outline: none !important;
  border: none !important;
  text-transform: uppercase;
  padding: 8px 24px;
  border-radius: 0px;
  font-size: 20px;
  margin-left: 0px !important;
}

.modal_action button:disabled {
  color: rgb(164, 164, 164);
  cursor: default;
}

.modal_form {
  div {
    width: 100%;
    margin-bottom: 16px;

    label {
      font-family: 'Futura PT Book';
      font-size: 18px;
      margin-bottom: 6px;

      small {
        color: red;
      }
    }

    input,
    textarea {
      width: 100%;
      border: 3px solid #C6B99D;
      color: rgb(0, 0, 0);
      padding: 8px 12px;
      background-color: transparent;
      border-radius: 0px;
      outline: none;
      font-family: 'Futura PT Book';
      font-size: 18px;

      &::placeholder {
        color: rgba(0, 0, 0, 0.7);
      }
    }
  }
}

.awssld__container {
  padding-bottom: 57% !important;
}

.awssld__controls button {
  width: 4% !important;
}

.awssld__controls__arrow-left:before,
.awssld__controls__arrow-left:after,
.awssld__controls__arrow-right:before,
.awssld__controls__arrow-right:after {
  background: #fff !important;
}

.awssld__bullets {
  bottom: 30px !important;
  z-index: 999999999 !important;
}

.awssld__bullets .awssld__bullets--active {
  background: #fff !important;
}

.awssld__bullets button {
  background: #ffffff4a !important;
}

.padded_content {
  padding-top: 120px;
  padding-bottom: 42px;
}

.breadcrumbs {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;

  &.light {
    margin-bottom: 32px !important;

    .breadcrumbs_item {
      color: rgba(255, 255, 255, 0.5) !important;

      &:last-child {
        color: rgba(255, 255, 255, 0.8) !important;
      }
    }
  }

  .breadcrumbs_item {
    color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-left: 4px;

    &:first-child {
      margin-left: 0px !important;
      cursor: pointer !important;
    }

    &:last-child {
      color: rgba(255, 255, 255, 0.8);
    }
  }
}

.page_title {
  font-weight: 700;
  font-size: 48px;
  line-height: 59px;
  color: #ffffff;

  &.light {
    color: #fff;
  }
}

.page_subtitle {
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  color: #000000;

  &.light {
    color: #fff;
  }
}

.page_desc {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #000000;

  &.light {
    color: #fff;
  }
}

@media screen and (max-width: 960px) {
  .awssld__container {
    padding-bottom: 160% !important;
  }
}

.awssld__controls__arrow-left,
.awssld__controls__arrow-right {
  height: 20px !important;
}

@media screen and (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1280px !important;
  }
}

.ant-collapse {
  border: none !important;
  border-radius: 0px !important;

  .ant-collapse-header-text {
    //color: #fff !important;
    font-size: 22px !important;
    font-weight: 500;
  }

  .ant-collapse-expand-icon {
    //color: #fff !important;
    height: 31px !important;

    span {
      font-size: 18px !important;

      svg {}
    }
  }

  .ant-collapse-content {
    background-color: transparent !important;
    border-top: none !important;

    //color: #fff !important;
    .ant-collapse-content-box {
      padding-left: 0px !important;
      padding-right: 0px !important;
      padding-top: 0px !important;
      opacity: 0.8;

      p {
        //color: #fff !important;
      }
    }
  }

  .ant-collapse-header {
    //flex-direction: row-reverse !important;
    padding-left: 0px !important;
    padding-right: 0px !important;

    .ant-collapse-header-text {
      font-size: 20px !important;
    }
  }

  .ant-collapse-item {
    border-radius: 0px !important;

    &:last-child {
      border-bottom: none !important;
    }
  }
}

.headSlider {
  width: 100% !important;
  position: absolute;

  img {
    width: 100%;
  }

  .hs_sl {
    height: 630px;
    width: 100%;

    div {
      background-position: top;
      background-repeat: no-repeat;
      background-size: cover;
      height: 630px;
    }
  }
}

.foodSlider {
  width: 101.3% !important;

  img {
    width: 90%;
  }
}

.arhbtn {
  margin-top: 32px;
  background-color: #C8B79D;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 12px 42px;
  border: none;
  outline: none;

  &.arhbtn-primary {
    background-color: #dfa25f;
    color: #fff;
    font-size: 20px;
    text-transform: uppercase;
  }

  &.arhbtn-round {
    background-color: #dfa25f;
    color: #fff;
    border-radius: 100px;
    width: 100%;
  }
}